import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      // retry: false,
      retry: (failureCount) => failureCount < 3,
    },
  },
});

export const wrapRootElementC = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    {element}
    <ReactQueryDevtools />
  </QueryClientProvider>
);
